import styled from 'styled-components'
import primitives from '@veneer/primitives'

export type OnlineStatusType = {
  color?: string
}

export const Wrapper = styled.li`
  display: flex;
  padding-bottom: ${primitives.layout.size3}px;
  align-items: center;
`

export const Body = styled.p`
  font-size: ${primitives.typography.size0};
  font-weight: 400;
  margin-left: 14px;
  line-height: ${primitives.typography.lineHeight1};
  font-family: ${primitives.typography.family0};
  flex: 3;

  &:last-of-type {
    flex: 1;
  }
`

export const Icon = styled.div<OnlineStatusType>`
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  border-radius: ${primitives.layout.cornerRadius1}px;
  background: ${(props) => props.color};
`
