import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Title = styled.p`
  font-weight: 400;
  font-size: ${primitives.typography.size2};
  font-family: ${primitives.typography.family0};
  margin: 0px;
`
export const Quantity = styled.div`
  font-size: ${primitives.typography.size2};
  font-weight: 400;
  font-family: ${primitives.typography.family0};
  margin: -2px;
`

type ValueProps = {
  variant?: string
}
export const Value = styled.span<ValueProps>`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size5};
  line-height: ${primitives.typography.lineHeight5};
  font-weight: 400;

  width: 27px;
  height: 32px;

  text-align: right;

  ${(props) =>
    props.variant === 'success' &&
    `
    color: #212121;
  `}

  ${(props) =>
    props.variant === 'error' &&
    `
    color: #be1313;
  `}
`
