import React, { useMemo } from 'react'
import IconShieldColor from '../IconShield'

import {
  Wrapper,
  WrapperTop,
  WrapperBottom,
  Title,
  Subtitle,
  Body
} from './styles'
import testId from '../../../data-testid'
import { useShellRootContext } from '../../../contexts/ShellRoot'

type Props = {
  securityList?: React.ReactNode
  riskLevel?: string
  totalDevices?: number
}

const BadgeSection = ({
  riskLevel = '',
  securityList,
  totalDevices
}: Props) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}
  const noDevices = Number(totalDevices) === 0

  const subtitle = useMemo(() => {
    if (typeof riskLevel !== 'string') {
      return ''
    }

    if (noDevices) {
      return t('endpoint-security.risk.not_available', 'Not Available')
    }

    const riskTranslation = {
      passed: t('endpoint-security.risk.low', 'Low Risk'),
      low: t('endpoint-security.risk.low', 'Low Risk'),
      med: t('endpoint-security.risk.medium', 'Medium Risk'),
      medium: t('endpoint-security.risk.medium', 'Medium Risk'),
      high: t('endpoint-security.risk.high', 'High Risk')
    }

    return riskTranslation[riskLevel.toLowerCase()] || ''
  }, [noDevices, riskLevel, t])

  return (
    <Wrapper>
      <WrapperTop>
        <IconShieldColor
          data-testid={testId('badge-icon')}
          faded={noDevices}
          riskLevel={subtitle}
        />
        <Body>
          <Title data-testid={testId('badge-title')}>
            {`${t(
              'ecp-endpoint-security-widget.titleFleetStatus',
              'YOUR FLEET STATUS'
            )}`}
          </Title>
          <Subtitle
            faded={noDevices}
            riskLevel={subtitle}
            data-testid={testId('badge-subtitle')}
          >
            {subtitle}
          </Subtitle>
        </Body>
      </WrapperTop>
      {securityList && <WrapperBottom>{securityList}</WrapperBottom>}
    </Wrapper>
  )
}

export default BadgeSection
