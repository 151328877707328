import React from 'react'

const IconCompliant = () => {
  return (
    <svg
      width="24"
      height="30"
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 1.5C12.2277 1.5 12.3992 1.57593 12.4837 1.64699C15.3054 4.01959 18.854 5.47936 22.476 5.75741C22.5829 5.76562 22.6667 5.87221 22.6667 6.00007V16.4999C22.6667 18.3006 22.5435 19.9237 22.3603 20.5387C22.1777 21.1513 21.864 21.7894 21.3876 22.5102C20.5248 23.8633 19.2457 24.8379 17.8311 25.851C16.1234 26.9665 14.2605 27.8428 12.294 28.4556C12.1994 28.4851 12.1005 28.5 12 28.5C11.8995 28.5 11.8005 28.4851 11.706 28.4556C9.73954 27.8428 7.87661 26.9665 6.21466 25.8823C4.75427 24.838 3.47522 23.8634 2.59884 22.4894C2.13605 21.7894 1.82233 21.1513 1.63977 20.5388C1.45648 19.9237 1.33333 18.3006 1.33333 16.4999V6.00007C1.33333 5.87221 1.41709 5.76562 1.52403 5.75741C5.14598 5.47936 8.69462 4.01959 11.5163 1.647C11.6008 1.57593 11.7723 1.5 12 1.5ZM12 0C11.5356 0 11.0713 0.148504 10.7181 0.445502C8.07314 2.66948 4.79852 4.00255 1.43325 4.26089C0.623311 4.32307 0 5.08678 0 6.00007V16.4999C0 17.9999 0.0863037 20.0448 0.37559 21.0155C0.627258 21.86 1.04061 22.6459 1.52897 23.3846C2.53477 24.9619 3.96136 26.0483 5.49939 27.1482C7.30595 28.3283 9.27588 29.2548 11.3501 29.9012C11.5615 29.967 11.7808 30 12 30C12.2193 30 12.4385 29.9671 12.6499 29.9012C14.7241 29.2548 16.6941 28.3283 18.5006 27.1482C20.0386 26.0483 21.4652 24.9619 22.471 23.3846C22.9594 22.6459 23.3727 21.86 23.6244 21.0155C23.9137 20.0448 24 17.9999 24 16.4999V6.00007C24 5.08678 23.3767 4.32307 22.5668 4.26089C19.2015 4.00255 15.9269 2.66948 13.2819 0.445502C12.9287 0.148504 12.4644 0 12 0Z"
        fill="#212121"
      />
    </svg>
  )
}
export default IconCompliant
