import React from 'react'
import { ListItem } from '@veneer/core'
import { Title, Quantity, Value } from './styles'
import testId from '../../../data-testid'

type Props = {
  icon?: React.ReactNode
  title?: string | React.ReactNode
  value?: string | number
  total?: string | number
  variant?: string
}

const SecurityItemsListItem = ({
  icon,
  title,
  value,
  total,
  variant
}: Props) => {
  return (
    <ListItem
      data-testid={testId('security-items-list-item')}
      leadingArea={<>{icon}</>}
      centerArea={
        <Title data-testid={testId('security-items-list-item-title')}>
          {title}
        </Title>
      }
      trailingArea={
        <Quantity data-testid={testId('security-items-list-item-quantity')}>
          <Value variant={variant}>
            {Number(total) === 0 ? '0' : Number(value)}
          </Value>{' '}
          / {Number(total) === 0 ? '0' : Number(total)}
        </Quantity>
      }
    />
  )
}

export default SecurityItemsListItem
