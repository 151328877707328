let localprops = {
  language: 'en',
  country: 'US',
  stack: null,
  authProvider: null,
  properties: { useOrgedAuthProvider: null },
  criterion: { check: null }
}

export const setProps = (props) => {
  localprops = props
}

export const getProps = () => {
  return localprops
}
