import styled from 'styled-components'
import primitives from '@veneer/primitives'

export const Wrapper = styled.div``

export const Gap = styled.span`
  display: inline-block;
  width: ${primitives.layout.size1}px;
  height: auto;
`

export const List = styled.ul`
  margin: ${primitives.layout.size3}px 0px;
`

export const Title = styled.label`
  font-size: ${primitives.typography.size1};
  margin: ${primitives.layout.size8}px ${primitives.layout.size0}px;
  font-family: ${primitives.typography.family0};
  letter-spacing: 0.04em;
  font-weight: 400;
  font-style: normal;
  line-height: ${primitives.typography.lineHeight2};
  color: ${primitives.color.gray12};
`
