import {
  Stack,
  SecurityMgrFleetClient,
  WebStratusClientRequestOptions
} from '@jarvis/web-stratus-client'

import useApiClient from './useApiClient'

export type SecurityMgtFleetOptions = WebStratusClientRequestOptions & {
  params?: {
    when?: string
    since?: string
  }
}

const useSecurityMgtFleetClient = (
  stack = Stack.dev,
  authProvider: object,
  method: string,
  options?: SecurityMgtFleetOptions
) => {
  const { data, ok, error, response, isFetching, fetchData } = useApiClient(
    SecurityMgrFleetClient,
    method,
    stack,
    authProvider,
    options
  )

  return {
    data,
    ok,
    error,
    response,
    isFetching,
    fetchData
  }
}

export default useSecurityMgtFleetClient
