/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import ChartSeriesListItem from '../ChartSeriesListItem'
import testId from '../../../data-testid'
import { Wrapper, Title, Gap, List } from './styles'

type Props = {
  title?: string
  leadingIcon?: any
  items?: Array<any>
  total?: number
}

const perc = (value, total) => {
  let percentage = (value * 100) / total
  percentage = isNaN(percentage) ? 0 : percentage
  return `${percentage.toFixed(2)}%`
}

const ChartSeriesList = ({
  leadingIcon,
  title,
  total,
  items = [],
  ...rest
}: Props) => {
  return (
    <Wrapper {...rest}>
      {title && (
        <Title data-testid={testId('chart-series-list-title')}>
          {title}
          <Gap />
          {leadingIcon}
        </Title>
      )}
      <List data-testid={testId('chart-series-list')}>
        {items.map((item, index) => {
          return (
            <ChartSeriesListItem
              key={index}
              name={item.name}
              value={item.value}
              percentage={perc(item.value, total)}
              color={item.color}
            />
          )
        })}
      </List>
    </Wrapper>
  )
}

export default ChartSeriesList
