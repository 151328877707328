import React from 'react'

import { Wrapper, Icon, Body } from './styles'

import testId from '../../../data-testid'

type Props = {
  color?: string
  name?: string
  value?: string | string
  percentage?: string
}

const ChartSeriesListItem = ({ color, name, value, percentage }: Props) => {
  return (
    <Wrapper data-testid={testId('chart-series-list-item')}>
      <Icon
        color={color}
        data-testid={testId('chart-series-list-item-icon')}
      />
      <Body>
        {name}: {percentage} ({Number(value) || '0'})
      </Body>
    </Wrapper>
  )
}

export default ChartSeriesListItem
