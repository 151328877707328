import React from 'react'
import { Chart as VeneerChart, ChartProvider } from '@veneer/core'
import highchartsInterface from '@veneer/highcharts-interface'

const Chart = ({ options }) => {
  return (
    <>
      <ChartProvider chartInterface={highchartsInterface}>
        <VeneerChart options={options} />
      </ChartProvider>
    </>
  )
}

export default Chart
