/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useCallback, useRef, useState } from 'react'
import {
  Stack,
  WebStratusClientRequestOptions
} from '@jarvis/web-stratus-client'

export type Client = Record<string, any>

export interface ClientConstructor<C = Client> {
  new (...args: [Stack, object]): C
}

const useApiClient = (
  Client: ClientConstructor,
  method: string,
  stack = Stack.dev,
  authProvider: object,
  options?: WebStratusClientRequestOptions
) => {
  const [data, setData] = useState<any>(null)
  const [ok, setOk] = useState<boolean>(false)
  const [response, setResponse] = useState<any>(null)
  const [error, setError] = useState<Record<string, any> | null>(null)
  const [isFetching, setIsFetching] = useState<boolean>(false)

  const client = useRef<Client>(null)

  const fetchData = useCallback(async () => {
    try {
      if (!client.current || !client.current[method]) {
        return
      }

      setIsFetching(true)
      setResponse(null)
      setError(null)
      setOk(false)

      const response = await client.current[method](options)
      setResponse(response)

      const ok = response.status > 199 && response.status < 400

      setOk(ok)

      if (!ok) {
        throw response.statusText
      }

      setData(response.data)
    } catch (err) {
      setError(err)
    } finally {
      setIsFetching(false)
    }
  }, [method, options])

  useEffect(() => {
    if (typeof Client !== 'function') {
      return
    }

    client.current = new Client(stack, authProvider)
    return () => {
      client.current = null
    }
  }, [Client, authProvider, stack])

  return {
    data,
    ok,
    response,
    error,
    isFetching,
    fetchData
  }
}

export default useApiClient
