import React from 'react'
import ChartSection from '../ChartSection'
import testId from '../../../data-testid'
import useShellRootContext from '../../../contexts/ShellRoot/useShellRootContext'

const NotAssessedChartSection = ({ chartOptions }) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}

  return (
    <ChartSection
      title={t(
        'endpoint-security.assessment-summary.assessed.title',
        'Assessed Status by Devices'
      )}
      data-testid={testId('not-assessed-chart-section')}
      chartOptions={chartOptions}
    />
  )
}

export default NotAssessedChartSection
