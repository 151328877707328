import styled from 'styled-components'
import primitives from '@veneer/primitives'
import { mediaQueries } from '../../../css.settings'

export const Container = styled.div`
  div:first-child > header + div {
    padding: 0;
  }
`

export const Body = styled.div`
  height: calc(250px - 40px);
  padding: ${primitives.layout.size5}px;

  overflow: hidden;

  @media (max-width: 992px) {
    height: auto;
  }

  display: flex;
  flex-direction: column;
  justify-content: stretch;

  ${mediaQueries.large`
    flex-direction: row;
  `}
`
export const LeftArea = styled.div`
  flex-grow: 1;
`
export const RightArea = styled.div`
  flex-grow: 1;
`

export const Separator = styled.hr`
  margin: 0 ${primitives.layout.size6}px;
  border: 0;
  height: ${primitives.layout.size1}px;
  background-color: rgba(33, 33, 33, 0.1);
  ${mediaQueries.large`
  width: 3px;
  height: auto;
  border-radius: 1.5px;

`}

  @media (max-width: 991px) {
    margin: ${primitives.layout.size6}px 0;
  }
`

export const BottomArea = styled.div`
  height: 52px;
  width: 100%;
  border-top: 1px solid rgba(33, 33, 33, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${primitives.layout.size4}px ${primitives.layout.size0}px
    ${primitives.layout.size4}px ${primitives.layout.size5}px;
  box-shadow: inset 0px -1px 0px rgba(33, 33, 33, 0.1);
`

export const HeaderTitle = styled.span`
  font-style: normal;
  font-size: ${primitives.typography.size4};
  line-height: ${primitives.typography.lineHeight3};
`
