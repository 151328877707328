/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react'

const useChartOptionsSeriesAsList = (chartOptions: Record<string, any>) => {
  const options = useMemo(
    () =>
      Object.keys(chartOptions).reduce(
        (acc, curr) => {
          if (curr === 'series') {
            const series = acc[curr]
            acc[curr] = series.map((serie: Record<string, any>) => {
              return {
                ...serie,
                data: serie.data.map(
                  (d: Array<string | number>, index: number) => {
                    const colors = chartOptions.colors
                    return {
                      color: (colors && colors[index]) || '',
                      name: d[0],
                      value: d[1]
                    }
                  }
                )
              }
            })
          }
          return acc
        },
        { ...chartOptions }
      ),
    [chartOptions]
  )

  return options
}
export default useChartOptionsSeriesAsList
