/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  css,
  FlattenSimpleInterpolation,
  SimpleInterpolation
} from 'styled-components'

import primitives from '@veneer/primitives'

export interface MediaSizesInterface {
  xsmallOnly: string
  small: string
  smallOnly: string
  medium: string
  mediumOnly: string
  large: string
  largeOnly: string
  xlarge: string
}

export interface MediaQueryInterface {
  [key: string]: (
    strings: TemplateStringsArray,
    ...interpolations: SimpleInterpolation[]
  ) => FlattenSimpleInterpolation
}

export const mediaSizes: MediaSizesInterface = {
  xsmallOnly: `screen and (max-width: ${primitives.layout.breakpoint0}px)`,
  small: `screen and (min-width: ${primitives.layout.breakpoint1}px)`,
  smallOnly: `screen and (min-width: ${primitives.layout.breakpoint1}px) and (max-width: ${primitives.layout.breakpoint2}px)`,
  medium: `screen and (min-width: ${primitives.layout.breakpoint3}px)`,
  mediumOnly: `screen and (min-width: ${primitives.layout.breakpoint3}px) and (max-width: ${primitives.layout.breakpoint4}px)`,
  large: `screen and (min-width: ${primitives.layout.breakpoint5}px)`,
  largeOnly: `screen and (min-width: ${primitives.layout.breakpoint5}px) and (max-width: ${primitives.layout.breakpoint6}px)`,
  xlarge: `screen and (min-width: ${primitives.layout.breakpoint7}px)`
}

export const mediaQueries = Object.keys(mediaSizes).reduce(
  (acc: MediaQueryInterface, size: string) => {
    acc[size] = (strings, ...interpolations) => css`
      @media ${(mediaSizes as any)[size]} {
        ${css(strings, ...interpolations)};
      }
    `
    return acc
  },
  {}
)
