import React from 'react'
import styled from 'styled-components'

import testId from '../../../data-testid'

const Wrapper = styled.p`
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WidgetBodyNoItems = () => {
  return (
    <Wrapper data-testid={testId('widget-body-no-items')}>
      Security information not available.
    </Wrapper>
  )
}

export default WidgetBodyNoItems
