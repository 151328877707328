/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { List } from '@veneer/core'
import SecurityItemsListItem from '../SecurityItemsListItem'

import testId from '../../../data-testid'

type Props = {
  items?: Array<any>
}

const SecurityItemsList = ({ items = [] }: Props) => {
  return (
    <List data-testid={testId('security-items-list')}>
      {items.map((item, index) => (
        <SecurityItemsListItem
          key={index}
          {...item}
        />
      ))}
    </List>
  )
}

export default SecurityItemsList
