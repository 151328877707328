import primitives from '@veneer/primitives'
import styled from 'styled-components'
import {
  LOW_RISK_CONSTANT,
  MEDIUM_RISK_CONSTANT,
  HIGH_RISK_CONSTANT
} from '../../../utils/constants'

export const Wrapper = styled.section``

export const WrapperTop = styled.div`
  display: flex;
  padding: ${primitives.layout.size3}px ${primitives.layout.size0}px
    ${primitives.layout.size3}px ${primitives.layout.size0}px;
  border-bottom: 3px solid rgba(33, 33, 33, 0.1);
  border-radius: 1.5px;
`
export const WrapperBottom = styled.div`
  li {
    padding: ${primitives.layout.size3}px ${primitives.layout.size2}px !important;
  }
  ul > li:first-child > div + div {
    padding-left: 14px;
  }
`

export const Body = styled.div`
  margin-left: ${primitives.layout.size4}px;
`
export const Title = styled.p`
  padding-top: 3px;
  color: #737373;
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size1};
  line-height: ${primitives.typography.lineHeight2};
  letter-spacing: 0.04em;
  text-align: left;
  font-weight: 400;
`

const createSubtitleColor = (props) => {
  if (props.riskLevel === LOW_RISK_CONSTANT) {
    return '#f1c029'
  } else if (props.riskLevel === MEDIUM_RISK_CONSTANT) {
    return '#d06702'
  } else if (props.riskLevel === HIGH_RISK_CONSTANT) {
    return '#be1313'
  } else {
    return '#f1c029'
  }
}

type SubtitleProps = {
  faded?: boolean
  riskLevel?: string
}

export const Subtitle = styled.p<SubtitleProps>`
  font-family: ${primitives.typography.family0};
  font-size: ${primitives.typography.size7};
  font-weight: 400;
  padding-top: ${primitives.layout.size1}px;
  color: ${(props) => (props.faded ? '#737373' : createSubtitleColor(props))};
`
