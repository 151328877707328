import { DirectionProvider } from '@veneer/core'
import { ThemeProvider } from '@veneer/theme/'
import React, { useEffect, useMemo, useState } from 'react'
import { App } from '../src'
import resources from '../src/assets/locale'
import { ShellRootProvider } from '../src/contexts/ShellRoot'
import {
  InterfacesType,
  ShellRootComponentProps,
  TranslatorFunctionType
} from '../src/types/shell'
import projectNames from './configs/projectNames'

export default function Root({
  properties,
  onMount,
  onUnmount,
  ...props
}: ShellRootComponentProps) {
  const [t, setT] = useState<TranslatorFunctionType>()
  // You can find all Shell´s props here. See "src/types/shell" for more details.
  // TODO: Declare the Shell type in shell-commons, then we can remove the eslint rule
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { v1, v2 } = (window as any).Shell as InterfacesType
  const interfaces = {
    v1,
    v2
  }

  const directionValue = v1?.localization?.useReactGetLanguageDirection?.(React)

  const { themeProviderProps, userThemeMode } = useMemo(() => {
    return {
      themeProviderProps: v1?.theme?.getThemeProviderProperties?.(),
      userThemeMode: v1?.theme?.getUserThemeMode?.()
    }
  }, [v1?.theme])

  useEffect(() => {
    if (typeof onMount === 'function') {
      onMount?.()
    }

    return () => {
      if (typeof onUnmount === 'function') {
        onUnmount?.()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Create a translator function by providing the resource files
  useEffect(() => {
    v1.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [v1])

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <DirectionProvider direction={directionValue}>
        <ThemeProvider
          {...themeProviderProps}
          mode={userThemeMode}
        >
          <ShellRootProvider {...{ interfaces, t, properties }}>
            <App {...{ ...props, properties }} />
          </ShellRootProvider>
        </ThemeProvider>
      </DirectionProvider>
    </section>
  )
}
