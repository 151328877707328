import React, { useEffect, useMemo, useState } from 'react'
import primitives from '@veneer/primitives'
import IconCompliant from './IconCompliant'
import IconNonCompliant from './IconNonCompliant'
import Widget from './Widget'
import WidgetBodyNoItems from './WidgetBodyNoItems'
import SecurityItemsList from './SecurityItemsList'
import BadgeSection from './BadgeSection'
import GeneralChartSection from './GeneralChartSection'
import AssesedChartSection from './AssesedChartSection'
import NotAssessedChartSection from './NotAssessedChartSection'
import useSecurityMgtFleetClient from '../../hooks/useSecurityMgtFleetClient'
import { useShellRootContext } from '../../contexts/ShellRoot'
import { getProps } from '../../utils/commonMethods'
import { MfePropsType } from 'src/types/mfeProps'

type MockPropsType = {
  properties?: {
    useOrgedAuthProvider?: boolean
  }
  authProvider?: {
    getAccessToken?: (forceRefresh?: boolean) => Promise<string>
    getIDToken?: (forceRefresh?: boolean) => Promise<string>
    onTokenExchangeRequired?: () => Promise<void>
    createOrgedAuthProvider?: () => string
    createOrglessAuthProvider?: () => string
  }
  criterion?: {
    check: () => boolean
  }
  navigation?: { push: (path: string) => void }
  authStack?: number
} & MfePropsType
const WidgetContainer = (mockProps?: MockPropsType) => {
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}
  const navigation = interfaces?.v1?.navigation || mockProps?.navigation
  const authStack =
    interfaces?.v1?.app?.getAuthStack?.() || mockProps?.authStack
  const authProvider = interfaces?.v2.authProvider || {}
  const useWindowSize = () => {
    const [windowSize, setWindowSize] = useState(window.innerWidth)
    const callback = () => setWindowSize(window.innerWidth)

    useEffect(() => {
      window.addEventListener('resize', callback)
      return () => window.removeEventListener('resize', callback)
    }, [])

    return windowSize
  }

  const resolution = useWindowSize()

  const isWideResolution =
    (resolution > 993 && resolution < 1100) ||
    (resolution > 1400 && resolution < 1500)
  const chartSize = isWideResolution ? '140' : '200'

  const DevicesSeries = {
    passed: `${t('ecp-endpoint-security-widget.risk.passed', 'Passed')}`,
    highRisk: `${t('ecp-endpoint-security-widget.risk.high', 'High Risk')}`,
    mediumRisk: `${t(
      'ecp-endpoint-security-widget.risk.medium',
      'Medium Risk'
    )}`,
    lowRisk: `${t('ecp-endpoint-security-widget.risk.low', 'Low Risk')}`,
    assessed: `${t(
      'ecp-endpoint-security-widget.chart.series.assessed',
      'Assessed'
    )}`,
    notAssessed: `${t(
      'ecp-endpoint-security-widget.chart.series.not-assessed',
      'Assessed'
    )}`,
    error: 'Error',
    notVerified: 'Not Verified',
    notAvailable: `${t(
      'ecp-endpoint-security-widget.risk.not_available',
      'Not Available'
    )}`
  }
  const props = mockProps.properties ? mockProps : getProps()

  const useOrgedAuthProvider: boolean = props.properties.useOrgedAuthProvider

  const baseAuthProvider = () => {
    if (useOrgedAuthProvider) {
      if (mockProps.authProvider) {
        return mockProps.authProvider
      } else {
        return authProvider.createOrgedAuthProvider()
      }
    } else return authProvider
  }
  const { data: summary, fetchData: fetchSummary } = useSecurityMgtFleetClient(
    authStack,
    baseAuthProvider(),
    'listSummary',
    {
      params: {
        when: '0d'
      }
    }
  )
  const { fetchData: fetchActivity } = useSecurityMgtFleetClient(
    authStack,
    baseAuthProvider(),
    'listActivity',
    {
      params: {
        since: '30d'
      }
    }
  )

  const securityListItems = useMemo(() => {
    const highRisk = Number(summary?.nonCompliant?.highRisk) || 0
    const mediumRisk = Number(summary?.nonCompliant?.mediumRisk) || 0
    const lowRisk = Number(summary?.nonCompliant?.lowRisk) || 0
    const nonCompliant = highRisk + mediumRisk + lowRisk

    return [
      {
        title: `${t(
          'endpoint-security.devices-compliant.title',
          'Devices Compliant'
        )}`,
        icon: <IconCompliant />,
        value: summary?.compliant || 0,
        total: summary?.total || 0,
        variant: 'success'
      },
      {
        title: `${t(
          'endpoint-security.devices-non-compliant.title',
          'Devices Noncompliant'
        )}`,
        icon: <IconNonCompliant />,
        value: nonCompliant,
        total: summary?.total || 0,
        variant: 'error'
      }
    ]
  }, [summary, t])

  const generalChartOptions = useMemo(() => {
    const disconnected = Number(summary?.notAssessed?.disconnected) || 0
    const unknown = Number(summary?.notAssessed?.unknown) || 0
    const notAssessed = disconnected + unknown
    const colors = ['#0c9f98', '#be1313', '#d06702', '#f1c029', '#8400be']
    const data = [
      [DevicesSeries.passed, Number(summary?.compliant) || 0],
      [DevicesSeries.highRisk, Number(summary?.nonCompliant?.highRisk) || 0],
      [
        DevicesSeries.mediumRisk,
        Number(summary?.nonCompliant?.mediumRisk) || 0
      ],
      [DevicesSeries.lowRisk, Number(summary?.nonCompliant?.lowRisk) || 0],
      [DevicesSeries.notAssessed, notAssessed]
    ]

    return {
      chart: {
        type: 'donut'
      },
      tooltip: {
        enabled: Number(summary?.total) ? true : false
      },
      plotOptions: {
        pie: {
          size: chartSize,
          states: {
            inactive: {
              opacity: 0.5
            }
          }
        }
      },
      title: {
        text: `<span style="font-size: 40px;">${
          Number(summary?.total) || '0'
        }</span>`
      },
      subtitle: {
        text: `<span style="font-size: 16px;">${t(
          'ecp-endpoint-security-widget.chart.subtitle',
          'total devices'
        )}</span>`
      },
      colors: Number(summary?.total) ? colors : [...colors, '#ebebeb'],
      total: summary?.total || 0,
      series: [
        {
          name: `${t('endpoint-security.devices', 'Devices')}`,
          data: Number(summary?.total)
            ? data
            : [...data, [DevicesSeries.notAvailable, 0.1]]
        }
      ]
    }
  }, [
    DevicesSeries.highRisk,
    DevicesSeries.lowRisk,
    DevicesSeries.mediumRisk,
    DevicesSeries.notAssessed,
    DevicesSeries.notAvailable,
    DevicesSeries.passed,
    chartSize,
    summary?.compliant,
    summary?.nonCompliant?.highRisk,
    summary?.nonCompliant?.lowRisk,
    summary?.nonCompliant?.mediumRisk,
    summary?.notAssessed?.disconnected,
    summary?.notAssessed?.unknown,
    summary?.total,
    t
  ])

  const assessedChartOptions = useMemo(() => {
    const compliant = Number(summary?.compliant)
    const highRisk = Number(summary?.nonCompliant?.highRisk)
    const mediumRisk = Number(summary?.nonCompliant?.mediumRisk)
    const lowRisk = Number(summary?.nonCompliant?.lowRisk)
    const count = compliant + highRisk + mediumRisk + lowRisk
    const total = summary?.total || 0

    return {
      chart: {
        type: 'donut'
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          size: chartSize,
          states: {
            inactive: {
              opacity: 0.5
            }
          }
        }
      },
      title: {
        text: `<span style="font-size: 40px;">${count || 0}</span>`
      },
      subtitle: {
        text: `<span style="font-size: 16px;">${t(
          'endpoint-security.assessment-summary.assessed.chart.subtitle',
          { total }
        )}</span>`
      },
      colors: ['#0c9f98', '#be1313', '#d06702', '#f1c029', '#8400be'],
      series: [
        {
          name: `${t(
            'endpoint-security.assessment-summary.assessed.series.title',
            {
              count
            }
          )}`,
          data: [
            [DevicesSeries.passed, summary?.compliant || 0],
            [DevicesSeries.highRisk, summary?.nonCompliant?.highRisk || 0],
            [DevicesSeries.mediumRisk, summary?.nonCompliant?.mediumRisk || 0],
            [DevicesSeries.lowRisk, summary?.nonCompliant?.lowRisk || 0]
          ]
        }
      ]
    }
  }, [
    DevicesSeries.highRisk,
    DevicesSeries.lowRisk,
    DevicesSeries.mediumRisk,
    DevicesSeries.passed,
    chartSize,
    summary?.compliant,
    summary?.nonCompliant?.highRisk,
    summary?.nonCompliant?.lowRisk,
    summary?.nonCompliant?.mediumRisk,
    summary?.total,
    t
  ])

  const notAssessedChartOptions = useMemo(() => {
    const disconnected = Number(summary?.notAssessed?.disconnected)
    const unknown = Number(summary?.notAssessed?.unknown)
    const count = disconnected + unknown
    const total = summary?.total

    return {
      chart: {
        type: 'donut'
      },
      tooltip: {
        enabled: false
      },
      plotOptions: {
        pie: {
          size: chartSize,
          states: {
            inactive: {
              opacity: 0.5
            }
          }
        }
      },
      title: {
        text: `<span style="font-size: 40px;">${count || 0}</span>`
      },
      subtitle: {
        text: `<span style="font-size: 16px;">${t(
          'endpoint-security.assessment-summary.assessed.chart.subtitle',
          { total }
        )}</span>`
      },
      colors: ['#be1313', primitives.color.gray6],
      series: [
        {
          name: `${t(
            'endpoint-security.assessment-summary.assessed.series.title',
            {
              defaultValue: 'total devices:',
              count
            }
          )}`,
          data: [
            [DevicesSeries.error, summary?.notAssessed?.disconnected || 0],
            [DevicesSeries.notVerified, summary?.notAssessed?.unknown || 0]
          ]
        }
      ]
    }
  }, [
    DevicesSeries.error,
    DevicesSeries.notVerified,
    chartSize,
    summary?.notAssessed?.disconnected,
    summary?.notAssessed?.unknown,
    summary?.total,
    t
  ])

  const [showSummary] = useState(true)
  const handleTitle = () => {
    if (showSummary) {
      return `${t(
        'ecp-endpoint-security-widget.header.title',
        'HP Secure Fleet Manager'
      )}`
    } else {
      return `${t(
        'endpoint-security.assessment-summary.header.title',
        'Assessment Summary'
      )}`
    }
  }
  React.useEffect(() => {
    fetchSummary()
    fetchActivity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return securityListItems.length ? (
    <Widget
      title={handleTitle()}
      navigation={navigation}
      leftArea={
        showSummary ? (
          <BadgeSection
            riskLevel={summary?.fleetRiskLevel || ''}
            securityList={<SecurityItemsList items={securityListItems} />}
            totalDevices={summary?.total || 0}
          />
        ) : (
          <AssesedChartSection chartOptions={assessedChartOptions} />
        )
      }
      rightArea={
        showSummary ? (
          <GeneralChartSection chartOptions={generalChartOptions} />
        ) : (
          <NotAssessedChartSection chartOptions={notAssessedChartOptions} />
        )
      }
      {...props}
    />
  ) : (
    <Widget>
      <WidgetBodyNoItems />
    </Widget>
  )
}

export default WidgetContainer
