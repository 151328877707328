import React from 'react'

const IconNonCompliant = () => {
  return (
    <svg
      width="27"
      height="29.93"
      viewBox="0 0 27 29.93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8298 26.5952L23.7872 23.5526C24.5959 22.5272 25.2411 21.381 25.6194 20.0812C26.0742 18.52 25.9889 16.872 25.9889 15.2628V6.14008C25.9889 5.37451 25.4151 4.70151 24.6512 4.64914C21.1022 4.4057 17.6697 3.00659 14.9455 0.656067C14.6741 0.421783 14.3343 0.304688 13.9951 0.304688C13.6557 0.304688 13.3166 0.421784 13.0448 0.656067C10.6776 2.69852 7.77496 4.02017 4.72653 4.49194L1.37378 1.13919C1.08081 0.846222 0.606201 0.846222 0.313232 1.13919C0.0202638 1.43216 0.0202636 1.90677 0.313232 2.19974L25.7693 27.6558C25.9158 27.8023 26.1077 27.8755 26.2996 27.8755C26.4915 27.8755 26.6833 27.8023 26.8298 27.6558C27.1228 27.3628 27.1228 26.8882 26.8298 26.5952ZM13.9951 1.81732C16.9366 4.34363 20.6825 5.88043 24.4889 6.13998V15.2628L24.4922 15.9332C24.5017 17.2656 24.5105 18.524 24.1795 19.6619C23.8925 20.6475 23.3964 21.5812 22.7129 22.4783L5.99326 5.75867C8.93127 5.12659 11.7084 3.78131 13.9951 1.81732ZM20.6592 24.6009L21.7215 25.6631C21.3247 25.9979 20.9179 26.3219 20.4958 26.6251C18.6398 27.9189 16.611 28.927 14.4745 29.6188C14.3196 29.6689 14.1574 29.694 13.9951 29.694C13.8329 29.694 13.6707 29.6689 13.5158 29.6188C11.3793 28.927 9.35049 27.9189 7.49454 26.6251C5.1907 24.97 3.18204 22.8679 2.37088 20.0812C1.91605 18.52 2.00137 16.872 2.00137 15.2628V6.14008C2.00137 6.08212 2.02573 6.03049 2.03213 5.97382L3.44992 7.3916L3.50174 15.2628L3.49844 15.9332C3.48856 17.2656 3.47977 18.524 3.81082 19.6619C4.42313 21.7635 5.91397 23.6427 8.36978 25.4069C10.0869 26.6037 11.9795 27.5448 13.9776 28.1917C13.9834 28.1897 13.9893 28.1873 13.9951 28.1854C16.0214 27.5389 17.9085 26.6002 19.6381 25.3945C20.0017 25.1332 20.3375 24.8681 20.6592 24.6009Z"
        fill="#212121"
      />
    </svg>
  )
}
export default IconNonCompliant
