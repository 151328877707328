/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import styled from 'styled-components'
import ChartSection from '../ChartSection'
import useChartOptionsSeriesAsList from '../../../hooks/useChartOptionsSeriesAsList'
import { List } from '../ChartSection/styles'
import testId from '../../../data-testid'
import useShellRootContext from '../../../contexts/ShellRoot/useShellRootContext'
import { IconInfo, Tooltip } from '@veneer/core'
import { TOOLTIP_INFO_CONSTANT } from '../../../utils/constants'

type Props = {
  chartOptions?: any
}

const Wrapper = styled(ChartSection)`
  align-items: center;
`
const GeneralChartSection = ({ chartOptions }: Props) => {
  const { t = (key, defaultValue) => defaultValue || key } =
    useShellRootContext?.() || {}
  const chartOptionsSeriesAsList = useChartOptionsSeriesAsList(chartOptions)

  const chartOptionsSeriesAsListAssessed =
    chartOptionsSeriesAsList.series[0]?.data.filter(
      (a) =>
        a.name !==
        t(
          'ecp-endpoint-security-widget.chart.series.not-assessed',
          'Not Assessed'
        )
    )

  const chartOptionsSeriesAsListNotAssessed =
    chartOptionsSeriesAsList.series[0]?.data.filter(
      (n) =>
        n.name ===
        t(
          'ecp-endpoint-security-widget.chart.series.not-assessed',
          'Not Assessed'
        )
    )

  const removeNotAvailableItem = chartOptionsSeriesAsListAssessed.some(
    (risk) =>
      risk?.name === t('endpoint-security.risk.not_available', 'Not Available')
  )
  if (removeNotAvailableItem) chartOptionsSeriesAsListAssessed.pop()

  const subIcon = (
    <Tooltip
      arrow
      content={`${t(
        'ecp-endpoint-security-widget.chart.tooltip',
        TOOLTIP_INFO_CONSTANT
      )}`}
      id="tooltip-arrow"
      placement="bottom-start"
    >
      <IconInfo size={12} />
    </Tooltip>
  )

  return (
    <Wrapper
      chartSeriesList={
        <>
          <List
            title={t(
              'ecp-endpoint-security-widget.chart.series.assessedCapital',
              'ASSESSED'
            )}
            leadingIcon={subIcon}
            items={chartOptionsSeriesAsListAssessed || []}
            total={chartOptionsSeriesAsList.total}
          />
          <List
            title={t(
              'ecp-endpoint-security-widget.chart.series.not-assessedCapital',
              'NOT ASSESSED'
            )}
            items={chartOptionsSeriesAsListNotAssessed || []}
            total={chartOptionsSeriesAsList.total}
          />
        </>
      }
      chartOptions={chartOptions}
      data-testid={testId('general-chart-section')}
    />
  )
}

export default React.memo(GeneralChartSection)
