import React, { ReactNode, useMemo } from 'react'
import Chart from '../Chart'

import useChartOptionsSeriesAsList from '../../../hooks/useChartOptionsSeriesAsList'

import { Wrapper, Body, ListWrapper, Title, List } from './styles'

import testId from '../../../data-testid'

type Props = {
  title?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  chartOptions: Record<string, any>
  chartSeriesList?: ReactNode
}

const ChartSection = ({
  title,
  chartOptions,
  chartSeriesList,
  ...rest
}: Props) => {
  const opts = useMemo(
    () => ({
      ...chartOptions,
      chart: {
        ...chartOptions.chart,
        width: 200,
        height: 200,
        margin: -90
      }
    }),
    [chartOptions]
  )

  const chartOptionsSeriesAsList = useChartOptionsSeriesAsList(opts)

  return (
    <Wrapper
      data-testid={testId('chart-section')}
      {...rest}
    >
      {title && (
        <Title data-testid={testId('chart-section-title')}>{title}</Title>
      )}
      <Body>
        <Chart options={opts} />
        <ListWrapper>
          {chartSeriesList || (
            <List
              title={chartOptionsSeriesAsList.series[0].name}
              items={chartOptionsSeriesAsList.series[0]?.data || []}
            />
          )}
        </ListWrapper>
      </Body>
    </Wrapper>
  )
}

export default ChartSection
