import React from 'react'
import {
  HIGH_RISK_CONSTANT,
  LOW_RISK_CONSTANT,
  MEDIUM_RISK_CONSTANT
} from '../../../utils/constants'

const IconShieldColor = ({ faded, riskLevel, ...rest }) => {
  const createStopColor = () => {
    switch (riskLevel) {
      case LOW_RISK_CONSTANT:
        return '#ffde6a'
      case MEDIUM_RISK_CONSTANT:
        return '#f9c086'
      case HIGH_RISK_CONSTANT:
        return '#eb5e5e'
      default:
        return '#ffde6a'
    }
  }

  const createStopColorOffset = () => {
    switch (riskLevel) {
      case LOW_RISK_CONSTANT:
        return '#f1c029'
      case MEDIUM_RISK_CONSTANT:
        return '#d06702'
      case HIGH_RISK_CONSTANT:
        return '#be1313'
      default:
        return '#f1c029'
    }
  }

  return (
    <svg
      width="49.33"
      height="60.4"
      viewBox="0 0 49.33 60.4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M48 11.8947V30.1345C48 33.3445 48.18 36.6447 47.2801 39.7646C45.6601 45.3445 41.64 49.5446 37.0201 52.8746C33.3001 55.4545 29.25 57.4647 24.9901 58.8445C24.6601 58.9346 24.36 58.9945 24.0301 58.9945C23.7001 58.9945 23.3701 58.9346 23.07 58.8445C18.7801 57.4647 14.7301 55.4545 11.0102 52.8746C6.42008 49.5446 2.39999 45.3445 0.780061 39.7646C-0.119903 36.6447 0.0300608 33.3445 0.0300608 30.1345V11.8947C0.0300608 10.3646 1.17008 9.01459 2.7001 8.89465C9.81009 8.41455 16.68 5.62457 22.11 0.914551C22.68 0.464661 23.3401 0.224609 24.0301 0.224609C24.6902 0.224609 25.3801 0.464661 25.9201 0.914551C31.3801 5.62457 38.25 8.41455 45.33 8.89465C46.86 9.01459 48 10.3646 48 11.8947Z"
        fill={'url(#paint0_linear)'}
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="24.0177"
          y1="0.224609"
          x2="24.0177"
          y2="58.9945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor={faded ? '#ebebeb' : createStopColor()} />
          <stop
            offset="1"
            stopColor={faded ? '#adadad' : createStopColorOffset()}
          />
        </linearGradient>
      </defs>
    </svg>
  )
}
export default IconShieldColor
