import primitives from '@veneer/primitives'
import styled from 'styled-components'
import ChartSeriesList from '../ChartSeriesList'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Body = styled.div`
  display: flex;

  @media (max-width: 475px) {
    flex-direction: column;
  }
`

export const Title = styled.h4`
  font-size: ${primitives.typography.size5};
  margin-bottom: ${primitives.layout.size5}px;
`

export const ListWrapper = styled.div`
  align-items: center;

  @media (max-width: 475px) {
    margin-top: ${primitives.layout.size2}px;
  }
`

export const List = styled(ChartSeriesList)`
  margin-left: ${primitives.layout.size4}px;
`
