/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useCallback } from 'react'
import { Button, IconChevronRight, Card } from '@veneer/core'
import {
  Container,
  Body,
  LeftArea,
  RightArea,
  Separator,
  BottomArea,
  CardHeader,
  HeaderTitle
} from './styles'
import testId from '../../../data-testid'
import { useShellRootContext } from '../../../contexts/ShellRoot'
import { isServiceCustomer } from '../../../utils/api'
type Props = {
  title?: string
  navigation?: any
  leftArea?: React.ReactNode | Array<React.ReactNode>
  rightArea?: JSX.Element | Array<JSX.Element>
  children?: React.ReactNode | Array<React.ReactNode>
  properties?: {
    useOrgedAuthProvider?: boolean
  }
  criterion?: { check: (options: any) => boolean }
}

const Widget = ({
  title,
  navigation,
  leftArea,
  rightArea,
  children,
  ...props
}: Props) => {
  const { t = (key, defaultValue) => defaultValue || key, interfaces } =
    useShellRootContext?.() || {}

  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const criterion = interfaces?.v1?.criterion || props.criterion
  const useOrgedAuthProvider = props.properties?.useOrgedAuthProvider

  const getServiceCustomer = useCallback(async () => {
    const response = await isServiceCustomer(criterion)
    setIsDisabled(useOrgedAuthProvider && !response)
    return response
  }, [criterion, useOrgedAuthProvider])

  useEffect(() => {
    getServiceCustomer()
  }, [getServiceCustomer])
  const getContent = (
    <Container data-testid="widget-container">
      <CardHeader data-testid="ecp-card-header">
        <HeaderTitle data-testid="widget-card">{title}</HeaderTitle>
      </CardHeader>
      {children || (
        <>
          <Body data-testid="widget-body">
            <LeftArea data-testid={testId('widget-left-area')}>
              {leftArea}
            </LeftArea>
            <Separator />
            <RightArea data-testid={testId('widget-right-area')}>
              {rightArea}
            </RightArea>
          </Body>
          <BottomArea data-testid="widget-bottomArea">
            <Button
              onClick={() => navigation.push('/solutions/security')}
              data-testid={testId('view-details-button')}
              appearance="ghost"
              small={true}
              trailingIcon={<IconChevronRight />}
              disabled={isDisabled}
            >
              {t('ecp-endpoint-security-widget.header.button', 'View Details')}
            </Button>
          </BottomArea>
        </>
      )}
    </Container>
  )
  return <Card content={getContent} />
}

export default Widget
